<template>
  <div>
    <div class="favorite-container">
      <!-- 封面 -->
      <div class="favorite-header my-animation-slide-top skeleton">
        <!-- 背景图片 -->
        <video ref="video" class="index-video" autoplay="autoplay" muted="muted" loop="loop"
               :src="wallpapers[wallpaperIndex]">
        </video>
        <div style="position: absolute;left: 0;top: 0;padding: 20px;width: 100%;height: 100%;">
          <!-- 标题 -->
          <div style="color: var(--white);margin: 10px">
            <div style="text-shadow: 2px 2px 5px #ffae44;">
              记录
            </div>
            <div style="font-size: 36px;font-weight: bold;line-height: 2;text-shadow: 2px 2px 5px #ffac40;">
              百宝箱 <i @click="wallpaperIndex = (wallpaperIndex + 1) % wallpapers.length;" class="el-icon-arrow-right favorite-dynamic-button" style="
                      font-size: 20px;
                      padding: 7px;
                      cursor: pointer;
                      vertical-align: text-bottom;
                      border-radius: 50%;
                      background-color: rgba(0, 0, 0, 0.286);"></i>
            </div>
          </div>
          <div class="card-container">
            <!-- 收藏夹 -->
            <div @click="changeFavorite('COLLECTS_FLODER')"
                 class="card-item">
              <div class="favorite-image"></div>
              <div style="position: absolute;left: 0;top: 0;padding: 20px 25px 15px">
                <div class="card-name">
                  收藏夹
                </div>
                <div class="card-desc">
                  将 www.roginx.ink 添加到您的收藏夹吧
                </div>
              </div>
            </div>
            <!-- 专辑 -->
            <div @click="changeFavorite('PLAYLIST_LIST')"
                 class="card-item">
              <div class="favorite-image"></div>
              <div style="position: absolute;left: 0;top: 0;padding: 20px 25px 15px">
                <div class="card-name">
                  专辑夹
                </div>
                <div class="card-desc">
                  来听点歌放松一下吧~~
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>

      <!-- 内容 -->
      <div class="favorite-content my-animation-slide-bottom">
        <!-- 收藏夹 -->
        <div v-show=" !$common.isEmpty(collects)">
          <div v-for="(value, key) in collects" :key="key" style="margin-top: 20px">
            <div class="collect-classify">
              {{key}}
            </div>
            <div class="favorite-item-wrap">
              <div v-for="(item, index) in value" :key="index" @click="toUrl(item.url)" class="favorite-item">
                <div>
                  <el-avatar class="favorite-item-image" :size="60"
                             :src="item.cover">
                  </el-avatar>
                </div>
                <div style="width: calc(100% - 80px)">
                  <div class="favorite-item-title">
                    {{item.title}}
                  </div>
                  <div class="favorite-item-introduction">
                    {{item.introduction}}
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>

    <!-- 页脚 -->
    <div style="background: var(--favoriteBg)">
      <myFooter></myFooter>
    </div>
  </div>
</template>

<script>

  const myFooter = () => import( "./common/myFooter");
  export default {
    components: {
      myFooter
    },

    data() {
      return {
        card: "COLLECTS_FLODER",
        wallpaperIndex:0,
        wallpapers:[],
        collects: {}
      }
    },

    computed: {},

    watch: {

    },

    created() {
      this.wallpapers = JSON.parse(window.localStorage.getItem("favoriteWallpaper"))|| [];
      this.getDynamicWallpaperList();
      this.getCollect()
    },

    mounted() {
      document.querySelector('meta[name="keywords"]').setAttribute('content', document.body.innerText)
      document.querySelector('meta[name="description"]').setAttribute('content', document.body.innerText)
    },

    methods: {
      toUrl(url) {
        window.open(url);
      },
      changeFavorite(card) {
        this.card = card;
        this.getCollect(card);
      },
      getDynamicWallpaperList(){
        let dynamicWallpapers = window.localStorage.getItem("dynamicWallpapers")
        if(dynamicWallpapers &&  this.wallpapers.length === 0) {
          this.wallpapers = JSON.parse(dynamicWallpapers);
          this.wallpaperIndex = Math.floor(Math.random() * this.wallpapers.length)
          return;
        }
        this.$http.post("/alist/api/fs/list",
        {page:1,password:'',path:'/alist/wallpaper/Genshin%20Impact',per_page:40,refresh:false}, {
          headers: {
            'Authorization': 'eyJhbGciOiJIUzI1NiIsInR5cCI6IkpXVCJ9.eyJ1c2VybmFtZSI6ImphbngiLCJleHAiOjE2ODQ5MzgwMDUsIm5iZiI6MTY4NDc2NTIwNSwiaWF0IjoxNjg0NzY1MjA1fQ.OSMLBW4mOnjjFIdxL-_BJ2nPmXfP1Du_qN4HONc0tMk'
          }
        })
          .then((res) => {
            if (!this.$common.isEmpty(res.data)) {
              this.wallpapers = res.data.content.map(item => {
                const url = `/api/alist/d/alist/wallpaper/Genshin%20Impact/${item.name}`; // 在原名字前加上前缀
                return url;
              })
              this.wallpaperIndex = Math.floor(Math.random() * this.wallpapers.length)
              window.localStorage.setItem("dynamicWallpapers",JSON.stringify(this.wallpapers))
            }
          })
          .catch((error) => {
            this.$message({
              message: error.message,
              type: "error"
            });
          });
      },
      getCollect(type = "COLLECTS_FLODER") {
        if (localStorage.getItem(type)) {
          this.collects = JSON.parse(localStorage.getItem(type))
        } else {
          this.collects =  {};
        }
      }
    }
  }
</script>

<style scoped>
  .favorite-container {
    padding: 25px;
    background: var(--favoriteBg);
  }
  .index-video {
    position: absolute;
    top: 0;
    left: 0;
    height: auto;
    min-height: 480px;
    width: 1200px;
    object-fit: cover;
    animation-direction: alternate;
    animation: move-video 38s infinite;
  }
  @keyframes move-video {
    0% {
      transform: translateY(0);
    }
    50% {
      transform: translateY(calc(-100% + 480px));
    }
    100% {
      transform: translateY(0%);
    }
  }
  @media screen and (max-width: 620px) {
    .index-video {
      width: auto;
      height: 480px;
    }
    @keyframes move-video {
      0% {
        transform: translateX(0);
      }
      50% {
        transform: translateX(calc(-100% + 100vw - 50px));
      }
      100% {
        transform: translateX(0);
      }
    }
    .card-container {
      margin-top: 0;
    }
  }
  .favorite-header {
    margin: 60px auto 30px;
    height: 480px;
    position: relative;
    overflow: hidden;
    border-radius: 20px;
    max-width: 1200px;
    background-color: var(--background);
  }
  .favorite-dynamic-button::before{
    display: block;
    transition: .3s;
  }
  .favorite-dynamic-button:hover::before{
    transform: translateX(3px);
    font-weight: 700;
    transition: .3s;
  }
  .favorite-image::before {
    content: "";
    position: absolute;
    width: 100%;
    height: 100%;
    background-color: var(--translucent);
  }

  .card-container {
    display: flex;
    flex-wrap: wrap;
    margin-top:20px;
  }

  .card-item {
    transition: all 0.3s;
    position: relative;
    width: 250px;
    height: 120px;
    border-radius: 20px;
    animation: hideToShow 1s ease-in-out;
    cursor: pointer;
    overflow: hidden;
    margin: 10px;
    color: var(--white);
  }

  .card-item:hover {
    transform: translateY(-6px);
  }

  .card-name {
    font-weight: bold;
    font-size: 25px;
  }

  .card-name:after {
    top: 50px;
    width: 22px;
    left: 26px;
    height: 2px;
    background: var(--white);
    content: "";
    border-radius: 1px;
    position: absolute;
  }

  .card-desc {
    font-weight: bold;
    margin-top: 15px;
  }

  .favorite-content {
    margin: 0 auto;
    max-width: 1200px;
  }

  .collect-classify {
    font-size: 28px;
    font-weight: bold;
    margin-bottom: 10px;
  }

  .favorite-item-wrap {
    display: flex;
    flex-wrap: wrap;
    margin-left: -10px;
  }

  .favorite-item {
    transition: all 0.3s;
    border-radius: 12px;
    box-shadow: 0 8px 16px -4px #2c2d300c;
    background: var(--background);
    display: flex;
    width: calc(100% / 4 - 20px);
    max-width: 320px;
    height: 90px;
    overflow: hidden;
    padding: 15px;
    cursor: pointer;
    margin: 10px;
  }

  .favorite-item:hover {
    background: #425AEF;
    color: var(--white);
  }

  .favorite-item:hover .favorite-item-image {
    transition: all 0.6s;
    width: 0 !important;
    height: 0 !important;
    opacity: 0;
    margin-right: 0;
  }

  .favorite-item:hover div:nth-child(2) {
    width: 100% !important;
  }

  .favorite-item-image {
    margin-right: 20px;
    transition: all 0.3s;
  }

  .favorite-item-title {
    font-size: 19px;
    font-weight: bold;
    white-space: nowrap;
    text-overflow: ellipsis;
    overflow: hidden;
    margin-bottom: 5px;
  }

  .favorite-item-introduction {
    opacity: 0.7;
    font-weight: bold;
    letter-spacing: 1px;
    font-size: 14px;
    line-height: 1.2;
    overflow: hidden;
    text-overflow: ellipsis;
    display: -webkit-box;
    -webkit-line-clamp: 2;
    -webkit-box-orient: vertical;
  }

  @media screen and (max-width: 620px) {
    .card-container {
      margin-top: 0;
    }
  }

  @media screen and (max-width: 900px) {
    .favorite-item {
      width: calc(100% / 3 - 20px);
    }
  }

  @media screen and (max-width: 700px) {
    .favorite-item {
      width: calc(100% / 2 - 20px);
    }
  }

  @media screen and (max-width: 480px) {
    .favorite-item {
      width: calc(100% - 20px);
    }
  }
</style>
